import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCXRNOc6BNLnX-5BZnxabvldc6vqBfWIdk",
  authDomain: "satan-6c7fa.firebaseapp.com",
  databaseURL: "https://satan-6c7fa.firebaseio.com",
  projectId: "satan-6c7fa",
  storageBucket: "satan-6c7fa.appspot.com",
  messagingSenderId: "720561208451",
  appId: "1:720561208451:web:7372b2047ac0812ee6efb6",
  measurementId: "G-QWW0M2FHMF"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export {auth}