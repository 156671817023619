import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {auth} from '../../providers/firebase'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from '../../providers/AuthContext'
import logo from "../layout/logo.png";
import { Button, Input, Message } from 'semantic-ui-react';
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

const Login = () => {
  const [MessageBox, setMessageBox] = useState({ status: false, boxColor: "", boxTxt: "" });
  const {setTimeActive} = useAuthValue()
  const navigate = useNavigate()
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Bitte gebe eine E-Mail ein."),
    password: Yup.string().required("Bitte gebe ein Passwort ein."),
  });
  //
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log("submitting...");
      console.log(values);
      //
      signInWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        if(!auth.currentUser.emailVerified) {
          sendEmailVerification(auth.currentUser)
          .then(() => {
            setTimeActive(true)
            navigate('/verify-email')
            setSubmitting(false);
          })
        .catch(err => alert(err.message))
      } else {
        setMessageBox({ status: true, boxColor:"green", boxTxt: "Erfolreich eingeloggt."})
        setSubmitting(false);
      }
      })
      .catch(err => {
        setMessageBox({ status: true, boxColor:"red", boxTxt: err.message});
        setSubmitting(false);
      })
    },
  });
  //

  const { errors, touched, isSubmitting, handleChange, values, getFieldProps } =
  formik;

  return(
    <div className="loader">
      <div className='center1'>
      <div className={`auth`}>
        <img src={logo} alt="Logo" width="70%" />
        {MessageBox.status && (<Message size="tiny" color={MessageBox.boxColor}>{MessageBox.boxTxt}</Message>)}
        {((touched.email && errors.email) || (touched.password && errors.password)) && (<Message size="tiny" warning>Bitte gebe eine E-Mail und Passwort ein.</Message>)}
        <FormikProvider value={formik}>
              <Form
                autoComplete="off"
                noValidate
                onSubmit={formik.handleSubmit}
              >
          <Input 
            icon='at'
            iconPosition='left'
            placeholder="E-Mail"
            required
            name="email"
            id="email"
            value={values.email}
            type="email"
            {...getFieldProps("email")}
            onChange={handleChange}
            />
          <Input 
            icon='key'
            iconPosition='left'
            type='password'
            required
            placeholder='Passwort'
            name="password"
            id="password"
            value={values.password}
            {...getFieldProps("password")}
            onChange={handleChange}
            />
            <Button className={`fluid ui ${isSubmitting ? 'loading ' : ''}button`} color='red' type='submit'>ANMELDEN</Button>
            <div className="ui horizontal divider" style={{ margin: "0 0 0 0" }}>Oder</div>
            <div className="three ui buttons">
              <button className="ui black button"><i className="apple icon"></i>Apple</button>
              <button className="ui google plus button"><i className="google plus icon"></i>Google</button>
              <button className="ui green button"><i className="mobile alternate icon"></i>Mobil</button>
            </div>
            <div className="two ui buttons">
            <button className="mini red ui button"><Link to="/register" style={{ color: "white" }}><i className="user plus icon"></i>Konto erstellen</Link></button>
            <button className="mini ui button"><i className="question circle outline icon"></i>Hilfe</button>
            </div>
            </Form>
            </FormikProvider>
      </div>
    </div>
    </div>
  )
};

export default Login