import {Navigate, Outlet} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

export default function PrivateRoute() {
  const {currentUser} = useAuthValue()
  
  if(!currentUser?.emailVerified){
    console.log("User not logged in. Redirect to /login. | LAYOUT");
    return <Navigate to='/login' replace/>
  }
  return <Outlet variable="test" />
}